import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65f5b5ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  key: 0,
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = {
  type: "button",
  class: "btn btn-light-primary me-3",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = { class: "card-body pt-0" }
const _hoisted_12 = { class: "min-w-275px" }
const _hoisted_13 = { class: "min-w-225px" }
const _hoisted_14 = { style: {"min-width":"220px"} }
const _hoisted_15 = { class: "badge badge-light-primary" }
const _hoisted_16 = {
  key: 0,
  class: "badge text-uppercase badge-light-danger"
}
const _hoisted_17 = {
  key: 1,
  class: "badge text-uppercase badge-light-success"
}
const _hoisted_18 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_FilterDropdown = _resolveComponent("FilterDropdown")!
  const _component_product_info = _resolveComponent("product-info")!
  const _component_sku_info = _resolveComponent("sku-info")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatable = _resolveComponent("MBDatatable")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
            class: "form-control form-control-solid w-250px ps-14",
            placeholder: _ctx.t('common.search')
          }, null, 40, _hoisted_6), [
            [_vModelText, _ctx.search]
          ]),
          _withDirectives(_createElementVNode("span", {
            class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle",
            style: {"cursor":"pointer"},
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeSearch && _ctx.removeSearch(...args)))
          }, [
            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
          ], 512), [
            [_vShow, _ctx.search != '']
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.checkedCompanys.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("button", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen031.svg" })
                ]),
                _createTextVNode(_toDisplayString(_ctx.t("common.filter")), 1)
              ]),
              _createVNode(_component_FilterDropdown, {
                onSubmitFilter: _ctx.handleFilter,
                onResetFilter: _ctx.handleFilterReset
              }, null, 8, ["onSubmitFilter", "onResetFilter"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_MBDatatable, {
        loading: _ctx.loading,
        "table-data": _ctx.tableData,
        "table-header": _ctx.tableHeader,
        "enable-items-per-page-dropdown": true,
        "current-page": _ctx.currentPage,
        total: _ctx.total,
        "rows-per-page": _ctx.pageSize,
        onCurrentChange: _ctx.onCurrentPageChange,
        onItemsPerPageChange: _ctx.onRowsPerPageChange,
        onSort: _ctx.onColumnSort
      }, {
        "cell-image": _withCtx(({ row }) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_product_info, {
              gallery: _ctx.getProductIdData(row.sku_identifier)?.gallery,
              name: _ctx.getProductIdData(row.sku_identifier)?.name,
              name_en: _ctx.getProductIdData(row.sku_identifier)?.name_en,
              source_link: _ctx.getProductIdData(row.sku_identifier)?.source_link,
              brand_id: _ctx.getProductIdData(row.sku_identifier)?.__show.brand_id
            }, null, 8, ["gallery", "name", "name_en", "source_link", "brand_id"])
          ])), [
            [_directive_loading, _ctx.options.loadingProducts]
          ])
        ]),
        "cell-article_information": _withCtx(({ row }) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_sku_info, {
              brand_article_no: 
                _ctx.getProductIdData(row.sku_identifier)?.brand_article_no
              ,
              color: _ctx.getProductIdData(row.sku_identifier)?.original_color,
              size: _ctx.getProductIdData(row.sku_identifier)?.__show.size,
              product_identifier_id: 
                _ctx.getProductIdData(row.sku_identifier)?.product_identifier_id
              
            }, null, 8, ["brand_article_no", "color", "size", "product_identifier_id"])
          ])), [
            [_directive_loading, _ctx.options.loadingProducts]
          ])
        ]),
        "cell-article_attributes": _withCtx(({ row: item }) => [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("p", null, _toDisplayString(_ctx.t("order.size")) + ": " + _toDisplayString(_ctx.getProductIdData(item.sku_identifier)?.__show.size), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.t("order.color")) + ": " + _toDisplayString(_ctx.getProductIdData(item.sku_identifier)?.original_color), 1)
          ])
        ]),
        "cell-sku_identifier": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.sku_identifier), 1)
        ]),
        "cell-identifier_code": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.identifier_code), 1)
        ]),
        "cell-warehouse": _withCtx(({ row: item }) => [
          _createElementVNode("span", _hoisted_15, _toDisplayString(item.__show.warehouse), 1)
        ]),
        "cell-check_point": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.check_point), 1)
        ]),
        "cell-inspection_manager": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.__show.inspection_manager), 1)
        ]),
        "cell-inspection_notes": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.inspection_notes), 1)
        ]),
        "cell-inspection_status": _withCtx(({ row: item }) => [
          (item.inspection_status == 10)
            ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.t("inspectionReport.failed")), 1))
            : (item.inspection_status == 20)
              ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.t("inspectionReport.passed")), 1))
              : _createCommentVNode("", true)
        ]),
        "cell-actions": _withCtx(({ row: item }) => [
          _createVNode(_component_router_link, {
            to: '/documents/inspection-report/' + item.id + '/edit',
            class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_18, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
              ])
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _: 1
      }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onSort"])
    ])
  ]))
}