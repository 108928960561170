
import { defineComponent, ref, onMounted, onActivated, computed } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import FilterDropdown from "@/views/documents/inspection-report/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import {
  ApiBase,
  ApiDocumentsSalesInvoice,
  ApiQualityInspection,
} from "@/core/api";
import _ from "lodash";
import fileDownload from "js-file-download";
import { useI18n } from "vue-i18n";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { InspectionReportTable } from "@/core/directive/interface/inspectionReport";
import { NumberOrString } from "@/core/directive/type/common";
import { getOrderProductIdData } from "@/core/directive/function/order";
import store from "@/store";
import {
  commonChangeFilterAddDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "documents-inspection-report",
  components: {
    MBDatatable,
    FilterDropdown,
    ProductInfo,
    SkuInfo,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<InspectionReportTable>>([]);
    const checkedCompanys = ref([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "created_at", direction: "desc" },
    ]);
    const downloadDisabled = ref<boolean>(false);
    let childWin: any;
    const tableHeader = ref([
      {
        name: t("order.image"),
        key: "image",
        sortable: false,
      },
      {
        name: t("order.articleInformation"),
        key: "article_information",
        sortable: false,
      },
      {
        name: t("order.articleAttributes"),
        key: "article_attributes",
        sortable: false,
      },
      {
        name: t("order.skuIdentifier"),
        key: "sku_identifier",
        sortable: false,
      },
      {
        name: t("inspectionReport.identifierCode"),
        key: "identifier_code",
        sortable: false,
      },
      {
        name: t("inspectionReport.warehouse"),
        key: "warehouse",
        sortable: false,
      },
      {
        name: t("inspectionReport.checkPoint"),
        key: "check_point",
        sortable: false,
      },
      {
        name: t("inspectionReport.inspectionManager"),
        key: "inspection_manager",
        sortable: false,
      },
      {
        name: t("inspectionReport.inspectionNotes"),
        key: "inspection_notes",
        sortable: false,
      },
      {
        name: t("inspectionReport.inspectionStatus"),
        key: "inspection_status",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      product_items: [],
      loadingProducts: true,
    });

    const getProductsData = async (ids) => {
      options.value.loadingProducts = true;
      const { data } = await ApiBase.getProductsData(ids);
      options.value.loadingProducts = false;
      if (data.code == 0) {
        options.value.product_items = data.data;
      }
    };

    const getProductIdData = computed(() => {
      return (id: NumberOrString) => {
        return getOrderProductIdData(id, options.value.product_items);
      };
    });

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getQualityInspectionList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    const getQualityInspectionList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiQualityInspection.getQualityInspectionList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            let ids: any = [];
            data.data.items.forEach((item) => {
              ids.push(item.sku_identifier);
            });
            getProductsData({
              sku_id: ids,
              merchant_id: 0,
            });
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getQualityInspectionList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getQualityInspectionList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getQualityInspectionList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (item == "invoice_date_start") {
            filterArr.push({
              field: item,
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "invoice_date_end") {
            filterArr.push({
              field: item,
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else if (item == "warehouse" || item == "inspection_status") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getQualityInspectionList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getQualityInspectionList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getQualityInspectionList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const statusClass = (status) => {
      let statusClass = "";
      switch (status) {
        case "New":
        case "Sell On Credit":
          statusClass = "badge-light-primary";
          break;
        case "Processing":
        case "Pending":
          statusClass = "badge-light-warning";
          break;
        case "Partial Shipped":
        case "Shipped":
          statusClass = "badge-light-info";
          break;
        case "Complete":
        case "Paid":
          statusClass = "badge-light-success";
          break;
        case "Refunded":
          statusClass = "badge-light-danger";
          break;
        case "Canceled":
          statusClass = "badge-light-dark";
          break;
        default:
          statusClass = "badge-light-primary";
      }
      return statusClass;
    };

    const fileExport = (id, item) => {
      item.downloadDisabled = true;
      ApiDocumentsSalesInvoice.print({
        id: id,
        download_type: "stream",
      })
        .then((data) => {
          console.log(data.headers["content-disposition"]);
          item.downloadDisabled = false;
          const disposition = data.headers["content-disposition"];
          fileDownload(data.data, disposition.split("filename=")[1]);
        })
        .catch((error) => {
          item.downloadDisabled = false;
          console.log(error);
        });
    };

    const fileShow = (id, item) => {
      item.showDisabled = true;
      ApiDocumentsSalesInvoice.print2({
        id: id,
        download_type: "url",
      })
        .then(({ data }) => {
          item.showDisabled = false;
          childWin = window.open(
            data.data.url,
            "newwindow",
            "height=700, width=600, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
          );
          childWin.onload = function () {
            // childWin.print();
          };
        })
        .catch((error) => {
          item.showDisabled = false;
          console.log(error);
        });
    };

    return {
      t,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      checkedCompanys,
      downloadDisabled,
      options,
      getQualityInspectionList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      removeSearch,
      handleFilter,
      handleFilterReset,
      updateList,
      statusClass,
      fileExport,
      fileShow,
      getProductIdData,
    };
  },
});
