
import { defineComponent, ref, onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import { ApiFulfillmentOrders } from "@/core/api";

interface Filter {
  sku_identifier: string;
  identifier_code: string;
  warehouse: string;
  inspection_manager: string;
  inspection_status: string;
}

export default defineComponent({
  name: "filter-sales-invoice-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const options = ref({
      warehouse: [],
      inspections: [],
      inspection_status: [
        {
          label: t("inspectionReport.failed"),
          value: "10",
        },
        {
          label: t("inspectionReport.passed"),
          value: "20",
        },
      ],
    });

    const formData = ref<Filter>({
      sku_identifier: "",
      identifier_code: "",
      warehouse: "",
      inspection_manager: "",
      inspection_status: "",
    });

    const getWarehouseData = async () => {
      const { data } = await ApiFulfillmentOrders.getWarehouseData({});
      if (data.code == 0) {
        options.value.warehouse = data.data;
      }
    };

    const getFromInfo = () => {
      // loading.value = true;
      Promise.all([getWarehouseData()])
        .then(() => {
          // loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onBeforeMount(() => {
      getFromInfo();
    });

    return {
      t,
      formData,
      formRef,
      submit,
      handleReset,
      options,
    };
  },
});
